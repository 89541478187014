<template>
  <div>
    <h3 class="page-title">تىزىملىك باشقۇرۇش</h3>
    <el-divider></el-divider>
    <el-row class="rtl-right">
      <el-button type="primary" plain icon="el-icon-circle-plus-outline" v-if="pageTags.create" @click="add">قوشۇش </el-button>
    </el-row>
    <!-- table-box -->
    <div class="table-box" v-if="pageTags.list">
      <el-table
        :data="tableData" 
        border 
        style="width: 100%" 
        v-loading="loading">
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table :data="props.row.menus" border style="width: 100%">
              <el-table-column prop="name" label="كىچىك تىزىملىك"></el-table-column>
              <el-table-column prop="tag" label="path"></el-table-column>
              <el-table-column prop="order" label="رەت تەرتىپى"></el-table-column>
              <el-table-column label="مەشغۇلات" prop="dosome" width="300">
                <template slot-scope="scope">
                  <el-button size="mini" type="primary" @click="handleSubEdit(scope.$index, scope.row)">تەھرىرلەش </el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>

        <el-table-column prop="name" label="تىزىملىك ئىسمى"></el-table-column>
        <el-table-column prop="tag" label="icon-class"></el-table-column>
        <el-table-column prop="order" label="رەت تەرتىپى"></el-table-column>
        <el-table-column label="مەشغۇلات" prop="dosome" width="300">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              v-if="pageTags.update"
              @click="handleEdit(scope.$index, scope.row)">تەھرىرلەش </el-button>
            <el-button
              size="mini"
              type="danger"
              v-if="pageTags.delete"
              @click="handleDelete(scope.$index, scope.row)">ئۆچۈرۈش </el-button>
          </template>
        </el-table-column>
      </el-table> 
    </div>

    <!-- el-dialog menus-manage-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div class="form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">
          <el-form-item label="تىزىملىك ئىسمى" prop="name">
            <el-input v-model="ruleForm.name" placeholder="تىزىملىك ئىسمى"></el-input>
          </el-form-item>
          <el-form-item label="icon-class" prop="tag">
            <el-input v-model="ruleForm.tag" placeholder="icon-class"></el-input>
          </el-form-item>
          <el-form-item label="رەت تەرتىپى" prop="order">
            <el-input v-model="ruleForm.order" placeholder="رەت تەرتىپى"></el-input>
          </el-form-item>
          <el-form-item label="كىچىك تىزىملىك ">
            <el-select v-model="ruleForm.menus_id" class="rtl-right" filterable multiple placeholder="كىچىك تىزىملىك ">
              <el-option
                v-for="item in subMenusList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :disabled="item.disabled">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm('ruleForm')">مۇقىملاش</el-button>
        <el-button type="primary" plain @click="resetForm('ruleForm')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="danger" plain @click="closeDialog('ruleForm')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

    <!-- el-dialog subMenus-manage-->
    <el-dialog
      title="كىچىك تىزىملىك تەھرىرلەش "
      :visible.sync="dialogSubVisible"
      width="30%"
      :before-close="handleSubClose">
      <div class="form-content">
        <el-form label-width="120px" :model="ruleSubForm" ref="ruleSubForm">
          <el-form-item label="كىچىك تىزىملىك" prop="name">
            <el-input v-model="ruleSubForm.name" placeholder="كىچىك تىزىملىك"></el-input>
          </el-form-item>
          <el-form-item label="path" prop="tag">
            <el-input v-model="ruleSubForm.tag" placeholder="path"></el-input>
          </el-form-item>
          <el-form-item label="رەت تەرتىپى" prop="order">
            <el-input v-model="ruleSubForm.order" placeholder="رەت تەرتىپى"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="submitSubForm('ruleSubForm')">مۇقىملاش</el-button>
        <el-button type="danger" @click="closeSubDialog('ruleSubForm')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  var self;
  export default {
    activated: function() {
      self = this;
      self.getList();
    },
    data() {
      return {
        tableData: [],
        loading: false,
        dialogVisible: false,
        ruleForm: {
          name:"",
          tag:"",
          order:"",
          menus_id:[]
        },
        editIndex: -1,
        dialogTitle:"",
        addDialog:true,
        editId:"",    
        adminId:[],
        subMenusList:[],
        dialogSubVisible:false,
        ruleSubForm:{
          name:"",
          tag:"",
          order:""
        },
        subEditId:"",
        pageTags:{}
      };
    },
    methods: {
      getList() {
        self.loading = true;

        self.$fetch("admin/menus").then(response => {
          if (response.status == 200) {
            self.tableData = response.data.data;
            self.getSubMenuList();
          } else{
            console.log(response.message);
          }

          self.loading = false;
          if(JSON.parse(sessionStorage.getItem("curTags"))){
            self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
          self.loading = false;
        });
      },
      add() {
        self.addDialog = true;
        self.dialogTitle = "قوشۇش ";
        self.dialogVisible = true;
      },
      handleEdit(index, row) {
        self.addDialog = false;
        self.dialogTitle = "تەھرىرلەش ";
        self.editId = row.id;
        self.ruleForm.name = row.name;
        self.ruleForm.tag = row.tag;
        self.ruleForm.order = row.order;

        var tmpArr = [];
        row.menus.forEach(function(item,index){
          tmpArr.push(item.id);
        });

        self.ruleForm.menus_id = tmpArr;
        self.dialogVisible = true;
      },
      handleDelete(index, row) {
        self.$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
          confirmButtonText: "مۇقىملاش",
          cancelButtonText: "بىكار قىلىش",
          type: "warning"
        }).then(() => {
          self.$remove('admin/menus/'+row.id).then((response) => {
            console.log(response);
            if(response.status == 204){
              self.getList();
              self.$message({
                message: "ئۆچۈرۈش تاماملاندى",
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response);
            }
          }).catch(err => {
            console.log('××××××××××××××××');
            console.log(err);
          });

        }).catch(() => {
          self.$message({
            type: "info",
            message: "ئۆچۈرۈش بىكار قىلىندى"
          });
        });
      },
      submitForm(formName) {
        if(self.addDialog){
          self.$post('admin/menus',{
            name:self.ruleForm.name,
            tag:self.ruleForm.tag,
            order:self.ruleForm.order,
            menus_id:self.ruleForm.menus_id
          }).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              console.log(response.data.message);
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }else{
          self.$put('admin/menus/'+self.editId,{
            name:self.ruleForm.name,
            tag:self.ruleForm.tag,
            order:self.ruleForm.order,
            menus_id:self.ruleForm.menus_id
          }).then((response) => {
            if(response.status == 201){
              self.closeDialog(formName);
              self.getList();
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200
              });
            }else{
              self.$message({
                message: response.data.message,
                type: "warning",
                duration: 1500
              });
            }
          }).catch(err => {
            console.log('××××××××××××××');
            console.log(err);
          });
        }
      },
      resetForm(formName) {
        self.$refs[formName].resetFields();
        self.ruleForm = {
          name:"",
          tag:"",
          order:"",
          menus_id:[]
        }
      },
      closeDialog(formName) {
        self.resetForm(formName);
        self.dialogVisible = false;
      },
      handleClose(done) {
        done();
        self.resetForm("ruleForm");
      },
      getSubMenuList(){
        self.$fetch("admin/menus",{
          filter:"submenu"
        }).then(response => {
          if (response.status == 200) {
            self.subMenusList = response.data.data;
            self.subMenusList.forEach(function(item,index){
              if(typeof item.menu_id == 'number'){
                self.$set(item,'disabled',true); //已经有主菜单
              }else{
                self.$set(item,'disabled',false);
              }
            });
          } else{
            console.log(response.message);
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      handleSubEdit(index,row){
        self.subEditId = row.id;
        self.ruleSubForm.name = row.name;
        self.ruleSubForm.tag = row.tag;
        self.ruleSubForm.order = row.order;
        self.dialogSubVisible = true;
      },
      closeSubDialog(){
        self.ruleSubForm = {
          name:"",
          tag:"",
          order:""
        };
        self.dialogSubVisible = false;
      },
      submitSubForm(formName){
        self.$put('admin/menus/'+self.subEditId,{
          name:self.ruleSubForm.name,
          tag:self.ruleSubForm.tag,
          order:self.ruleSubForm.order
        }).then((response) => {
          if(response.status == 201){
            self.closeSubDialog(formName);
            self.getList();
            self.$message({
              message: response.data.message,
              type: "success",
              duration: 1200
            });
          }else{
            self.$message({
              message: response.data.message,
              type: "warning",
              duration: 1500
            });
          }
        }).catch(err => {
          console.log('××××××××××××××');
          console.log(err);
        });
      },
      handleSubClose(done){
        done();
        self.$refs.ruleSubForm.resetFields();
        self.closeSubDialog();
      }
    }
  };
</script>

